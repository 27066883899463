// load the config according to the NODE_ENV environment variable
// fallback is config.dev.json
const loadConfig = () => {
  let configFile: string;
  switch (process.env.NODE_ENV) {
    case 'production':
      configFile = 'config.prod.json';
      break;
    case 'development':
      configFile = 'config.dev.json';
      break;
    default:
      configFile = 'config.dev.json';
  }

  return require('../../config/' + configFile);
};

export const config = loadConfig();
