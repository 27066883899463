import { useContext, useEffect } from 'react';
import { useSubheader } from '../../../../_metronic/layout';
import { Button, Card, Spinner, Table } from 'react-bootstrap';
import { useParams } from 'react-router';
import SyncIcon from '@material-ui/icons/Sync';
import InfoIcon from '@material-ui/icons/Info';
import { ClientContext, ClientContextType } from '../context/ClientContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type Params = {
  id: string;
};

export const ClientPage = () => {
  const { id } = useParams() as Params;
  const subheader = useSubheader();

  const {
    setClientId,
    fetchClientInformation,
    client,
    clientInformationLoaded
  } = useContext(ClientContext) as ClientContextType;

  useEffect(() => {
    if (subheader) {
      subheader.setTitle(id);
    }
  }, [subheader]);

  useEffect(() => {
    setClientId(id);
  }, [id]);

  let clientInformation: JSX.Element = (
    <Card>
      <Card.Body>
        <Spinner animation='border' variant='primary' />
      </Card.Body>
    </Card>
  );

  if (clientInformationLoaded) {
    let clientStatusRows: JSX.Element[] = [
      <tr key={null}>
        <td colSpan={3}>No status available</td>
      </tr>
    ];

    if (client.StatusLogs?.data && client.StatusLogs.data.length > 0) {
      clientStatusRows = client.StatusLogs.data.map((status, index) => (
        <tr key={index}>
          <td>{index}</td>
          <td>
            {status.CreatedAt
              ? dayjs
                  .utc(status.CreatedAt)
                  .local()
                  .format('DD.MM.YYYY, HH:mm:ss')
              : 'unknown'}
          </td>
          <td>{/* Display Debug Messages here in the future*/}</td>
        </tr>
      ));
    }

    clientInformation = (
      <div>
        <Card bg='light' className={'mb-5'}>
          <Card.Body>
            <InfoIcon className={'mr-1'} />
            <b>Last Time Online: </b>
            <span>
              {client?.StatusLogs?.data[0]?.CreatedAt
                ? dayjs
                    .utc(client.StatusLogs.data[0].CreatedAt)
                    .local()
                    .format('DD.MM.YYYY, HH:mm:ss')
                : 'unknown'}
            </span>
          </Card.Body>
        </Card>
        <Card className={'mb-5'}>
          <Card.Body>
            <Card.Title>Client Configuration</Card.Title>
            <Table striped bordered>
              <tbody>
                <tr>
                  <th>Building ID</th>
                  <td>{client?.Configuration?.BuildingId}</td>
                </tr>
                <tr>
                  <th>Calendar ID</th>
                  <td>{client?.Configuration?.CalendarId}</td>
                </tr>
                <tr>
                  <th>Language</th>
                  <td>{client?.Configuration?.Language}</td>
                </tr>
                <tr>
                  <th>View Mode</th>
                  <td>{client?.Configuration?.ViewMode}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Status Logs</Card.Title>
            <Table bordered>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Timestamp</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>{clientStatusRows}</tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Card className={'mb-5'}>
        <Card.Body>
          <Card.Title
            className={'d-flex align-items-center justify-content-between mb-0'}
          >
            <div>{id}</div>
            <Button
              variant='secondary'
              onClick={() => fetchClientInformation()}
            >
              Refresh
              <SyncIcon className={'ml-1'} />
            </Button>
          </Card.Title>
        </Card.Body>
      </Card>
      {clientInformation}
    </div>
  );
};
