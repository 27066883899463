import { useContext, useEffect, useState } from 'react';
import { useSubheader } from '../../../../_metronic/layout';
import { Button, Card, Spinner, Table } from 'react-bootstrap';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom';
import { ClientsContext, ClientsContextType } from '../context/ClientsContext';
import { RegisterClientDialog } from './dialogs/RegisterClientDialog';
import ErrorIcon from '@material-ui/icons/Error';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const ClientOverviewPage = () => {
  const subheader = useSubheader();

  const { clients, fetchClients, clientsLoaded } = useContext(
    ClientsContext
  ) as ClientsContextType;
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);

  let clientTableRows: JSX.Element[] = [
    <tr key={null}>
      <td colSpan={3}>
        <Spinner animation='border' variant='primary' />
      </td>
    </tr>
  ];

  useEffect(() => {
    if (subheader) {
      subheader.setTitle('Clients');
    }
  }, [subheader]);

  if (clientsLoaded) {
    clientTableRows = clients.map((client, index) => {
      const lastOnlineTimestamp =
        client.StatusLogs && client.StatusLogs.data[0]
          ? dayjs.utc(client.StatusLogs.data[0].CreatedAt)
          : null;
      const lastTimeOnline = lastOnlineTimestamp
        ? lastOnlineTimestamp.local().format('DD.MM.YYYY, HH:mm:ss')
        : '';
      const isOffline = lastOnlineTimestamp
        ? dayjs().diff(lastOnlineTimestamp, 'minute') > 15
        : false;
      return (
        <tr key={client.Id}>
          <td>{index}</td>
          <td>
            <NavLink to={'/clients/' + client.Id}>{client.Id}</NavLink>
          </td>
          <td>
            {lastTimeOnline}
            {isOffline && (
              <ErrorIcon color={'error'} style={{ marginLeft: 5 }} />
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title
            className={'d-flex align-items-center justify-content-between'}
          >
            <div>All Clients</div>
            <div>
              <Button
                className={'mr-3'}
                variant='secondary'
                onClick={() => fetchClients()}
              >
                Refresh
                <SyncIcon className={'ml-1'} />
              </Button>
              <Button
                variant='primary'
                onClick={() => setShowRegisterDialog(true)}
              >
                Register
                <AddIcon className={'ml-1'} />
              </Button>
            </div>
          </Card.Title>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Client ID</th>
                <th>Last Time Online</th>
              </tr>
            </thead>
            <tbody>{clientTableRows}</tbody>
          </Table>
        </Card.Body>
      </Card>
      <RegisterClientDialog
        show={showRegisterDialog}
        onHide={() => setShowRegisterDialog(false)}
      />
    </>
  );
};
