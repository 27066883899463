/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import { ClientsProvider } from './modules/Clients/context/ClientsContext';
import { AuthProvider } from './modules/Auth/context/AuthContext';

type Props = {
  basename: string | undefined;
};

export default function App({ basename }: Props) {
  return (
    /* Auth provider */
    <AuthProvider>
      {/* Clients provider */}
      <ClientsProvider>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/* This library only returns the location that has been active before the recent location change in the current window lifetime. */}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </ClientsProvider>
    </AuthProvider>
  );
}
