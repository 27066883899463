/* eslint-disable camelcase */
import { createContext, ReactNode, useEffect, useState } from 'react';
import { ApolloError, gql } from '@apollo/client';
import {
  GetAllClientsOverview,
  GetAllClientsOverview_clients
} from '../../../../_graphql/GetAllClientsOverview';
import { graphqlClient } from '../../ApiJsClient/ApiGraphqlClient';
import {
  CreateClient,
  CreateClientVariables
} from '../../../../_graphql/CreateClient';

export type ClientsContextType = {
  clients: GetAllClientsOverview_clients[];
  fetchClients: () => Promise<void>;
  clientsLoaded: boolean;
  registerClient: (clientId: string) => Promise<string>;
};

const ClientsContext = createContext(
  undefined as ClientsContextType | undefined
);

type ClientsProviderProps = {
  children: ReactNode;
};

const ClientsProvider = ({ children }: ClientsProviderProps) => {
  const [clients, setClients] = useState([] as GetAllClientsOverview_clients[]);
  const [clientsLoaded, setClientsLoaded] = useState(false);

  const fetchClients = async (): Promise<void> => {
    setClientsLoaded(false);

    await graphqlClient
      .query<GetAllClientsOverview>({
        fetchPolicy: 'no-cache',
        query: gql`
          query GetAllClientsOverview {
            clients {
              Id
              StatusLogs(
                first: 1
                orderBy: [{ column: CREATED_AT, order: DESC }]
              ) {
                data {
                  CreatedAt
                }
              }
            }
          }
        `
      })
      .then(async response => {
        setClients(response.data.clients);
        setClientsLoaded(true);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const registerClient = async (clientId: string): Promise<string> => {
    return graphqlClient
      .mutate<CreateClient, CreateClientVariables>({
        mutation: gql`
          mutation CreateClient($input: CreateClientInput!) {
            createClient(input: $input) {
              Id
            }
          }
        `,
        variables: {
          input: {
            Id: clientId
          }
        }
      })
      .then(async response => {
        await fetchClients();
        return (
          'Client ' +
          response.data?.createClient.Id +
          ' registered successfully'
        );
      })
      .catch(e => {
        console.error(e);
        // debugger;
        if (e instanceof ApolloError) {
          if (
            e.graphQLErrors.some(error => {
              return (
                error.extensions?.category === 'validation' &&
                error.extensions.validation['input.Id'].indexOf(
                  'validation.unique'
                ) !== -1
              );
            })
          ) {
            return 'Client ' + clientId + ' is already registered';
          } else {
            return 'Error registering client: ' + e;
          }
        } else {
          return 'Error registering client: ' + e.statusText;
        }
      });
  };

  useEffect(() => {
    fetchClients().then();
  }, []);

  const value: ClientsContextType = {
    clients: clients,
    fetchClients: fetchClients,
    clientsLoaded: clientsLoaded,
    registerClient: registerClient
  };

  return (
    <ClientsContext.Provider value={value}>{children}</ClientsContext.Provider>
  );
};

export { ClientsContext, ClientsProvider };
