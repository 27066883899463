import { config } from '../../../config/config';
import {
  ApiClientConfiguration,
  ApiClientFactory,
  ApiClientRepository,
  StorageData
} from '@datenkraft/meetingroom-js-api-client';

// Initialize ApiClientRepository
const initApiClientRepository = () => {
  const configParams = {
    basePath: config.apiUrl,
    fetchApi: window.fetch.bind(window),
    storagePersistence: {
      save(data: StorageData) {
        localStorage.setItem('apiClientStorage', JSON.stringify(data));
      },
      load() {
        const apiClientStorage = localStorage.getItem('apiClientStorage');
        if (apiClientStorage) {
          return JSON.parse(apiClientStorage);
        } else {
          return {};
        }
      }
    }
  };

  const apiClientConfiguration = new ApiClientConfiguration(configParams);
  const apiClientFactory = new ApiClientFactory(apiClientConfiguration);
  return new ApiClientRepository(apiClientFactory);
};

export const apiClientRepository = initApiClientRepository();
