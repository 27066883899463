/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { useContext } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Layout } from '../_metronic/layout';
import { BasePage } from './BasePage';
import { ErrorsPage } from './modules/Errors/pages/ErrorsPage';
import { Logout } from './modules/Auth';
import { AuthPage } from './modules/Auth';
import {
  AuthContext,
  AuthContextType
} from './modules/Auth/context/AuthContext';

export const Routes = () => {
  const { isAuthorized } = useContext(AuthContext) as AuthContextType;

  return (
    <Switch>
      {!isAuthorized ? (
        /* Render auth page when user at `/auth` and not authorized. */
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /* Otherwise redirect to root page (`/`) */
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!isAuthorized ? (
        /* Redirect to `/auth` when user is not authorized */
        <Redirect to='/auth/login' />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
};
