import React, { useContext } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { checkIsActive } from '../../../../_helpers';
import { ClientsContext } from '../../../../../app/modules/Clients/context/ClientsContext';

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = url => {
    return checkIsActive(location, url) ? 'menu-item-active' : '';
  };
  const { clients } = useContext(ClientsContext);

  const clientMenuItems = clients.map(client => (
    <li
      key={client.Id}
      className={`menu-item ${getMenuItemActive(
        '/clients/' + client.Id
      )}`}
    >
      <NavLink className='menu-link' to={'/clients/' + client.Id}>
        <span className='menu-text'>{client.Id}</span>
      </NavLink>
    </li>
  ));

  return (
    <div
      id='kt_header_menu'
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/* begin::Header Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Clients */}
        {/* begin::1 Level */}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup='true'
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            '/clients'
          )}`}
        >
          <NavLink className='menu-link menu-toggle' to='/clients'>
            <span className='menu-text'>Clients</span>
            <i className='menu-arrow' />
          </NavLink>
          <div className='menu-submenu menu-submenu-classic menu-submenu-left'>
            <ul className='menu-subnav'>
              {/* begin::2 Level */}
              {clientMenuItems}
              {/* end::2 Level */}
            </ul>
          </div>
        </li>
        {/* end::1 Level */}
      </ul>
      {/* end::Header Nav */}
    </div>
  );
}
