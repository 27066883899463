import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthContext, AuthContextType } from '../context/AuthContext';

export const Login = () => {
  const [loading, setLoading] = useState(false);

  const { login } = useContext(AuthContext) as AuthContextType;

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Required field'),
    password: Yup.string().required('Required field')
  });

  const initialValues = {
    username: '',
    password: ''
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname: 'username' | 'password') => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      login(values.username, values.password).then(success => {
        if (!success) {
          disableLoading();
          setSubmitting(false);
          setStatus('Invalid credentials.');
        }
      });
    }
  });

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>Login Account</h3>
        <p className='text-muted font-weight-bold'>
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/* begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        {formik.status ? (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <div />
        )}

        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Username'
            type='text'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'username'
            )}`}
            {...formik.getFieldProps('username')}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Password'
            type='password'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group d-flex flex-wrap align-items-center'>
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-100`}
          >
            <span>Sign In</span>
            {loading && <span className='ml-3 spinner spinner-white' />}
          </button>
        </div>
      </form>
      {/* end::Form*/}
    </div>
  );
};
