import { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_metronic/layout';
import { AuthContext, AuthContextType } from '../context/AuthContext';

export const Logout = () => {
  const { isAuthorized, logout } = useContext(AuthContext) as AuthContextType;

  useEffect(() => {
    logout();
  }, []);

  return isAuthorized ? <LayoutSplashScreen /> : <Redirect to='/auth/login' />;
};
