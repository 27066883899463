import {
  Input,
  ModalProgressBar
} from '../../../../../_metronic/_partials/controls';
import { Modal } from 'react-bootstrap';
import { useContext, useState } from 'react';
import {
  ClientsContext,
  ClientsContextType
} from '../../context/ClientsContext';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { config } from '../../../../../config/config';

type Props = {
  show: boolean;
  onHide: () => void;
};

const RegisterClientSchema = Yup.object().shape({
  clientId: Yup.string()
    .max(255, 'Maximum 255 characters')
    .matches(
      new RegExp(config.clientIdRegex),
      'Only alphanumeric characters and underscores allowed'
    )
    .required('Required')
});

export const RegisterClientDialog = ({ show, onHide }: Props) => {
  const { registerClient } = useContext(ClientsContext) as ClientsContextType;
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        setMessage('');
      }}
      aria-labelledby='register-client-modal-title'
    >
      {isLoading && <ModalProgressBar />}
      <Modal.Header>
        <Modal.Title id='register-client-modal-title'>
          Register Client
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{ clientId: '' }}
        validationSchema={RegisterClientSchema}
        onSubmit={values => {
          setIsLoading(true);
          registerClient(values.clientId).then(message => {
            setIsLoading(false);
            setMessage(message);
          });
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body>
              {isLoading ? (
                <span>Client is being registered ...</span>
              ) : message ? (
                <span>{message}</span>
              ) : (
                <Form className='form form-label-right'>
                  <div className='form-group'>
                    <Field
                      name='clientId'
                      component={Input}
                      placeholder='Client ID'
                      label='Client ID'
                    />
                  </div>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button
                  type='button'
                  onClick={() => {
                    onHide();
                    setMessage('');
                  }}
                  className='btn btn-light btn-elevate'
                >
                  Close
                </button>
                <> </>
                {!message && (
                  <button
                    type='button'
                    onClick={() => handleSubmit()}
                    className='btn btn-primary btn-elevate'
                  >
                    Register
                  </button>
                )}
              </div>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
