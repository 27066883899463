/* eslint-disable camelcase */
import { createContext, ReactNode, useEffect, useState } from 'react';
import { graphqlClient } from '../../ApiJsClient/ApiGraphqlClient';
import { gql } from '@apollo/client';
import {
  GetClientDetail,
  GetClientDetail_client,
  GetClientDetailVariables
} from '../../../../_graphql/GetClientDetail';

export type ClientContextType = {
  clientId: string;
  setClientId: (clientId: string) => void;
  client: GetClientDetail_client;
  fetchClientInformation: () => Promise<void>;
  clientInformationLoaded: boolean;
};

const ClientContext = createContext(undefined as ClientContextType | undefined);

type ClientProviderProps = {
  children: ReactNode;
};

const ClientProvider = ({ children }: ClientProviderProps) => {
  const [clientId, setClientId] = useState('');
  const [client, setClient] = useState(
    undefined as GetClientDetail_client | undefined
  );
  const [clientInformationLoaded, setClientInformationLoaded] = useState(false);

  const resetClientInformation = () => {
    setClient(undefined);
  };

  const fetchClientInformation = async (): Promise<void> => {
    setClientInformationLoaded(false);
    resetClientInformation();

    await graphqlClient
      .query<GetClientDetail, GetClientDetailVariables>({
        fetchPolicy: 'no-cache',
        query: gql`
          query GetClientDetail($clientId: ID!) {
            client(Id: $clientId) {
              StatusLogs(
                first: 1000
                orderBy: [{ column: CREATED_AT, order: DESC }]
              ) {
                data {
                  CreatedAt
                }
              }
              Configuration {
                BuildingId
                CalendarId
                Language
                ViewMode
              }
            }
          }
        `,
        variables: {
          clientId: clientId
        }
      })
      .then(response => {
        setClient(response.data.client as GetClientDetail_client);
      })
      .catch(e => {
        console.error(e);
      });

    setClientInformationLoaded(true);
  };

  useEffect(() => {
    if (clientId) {
      fetchClientInformation().then();
    }
  }, [clientId]);

  const value: ClientContextType = {
    clientId: clientId,
    setClientId: setClientId,
    client: client as GetClientDetail_client,
    fetchClientInformation: fetchClientInformation,
    clientInformationLoaded: clientInformationLoaded
  };

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export { ClientContext, ClientProvider };
