import { createContext, ReactNode, useEffect, useState } from 'react';
import { AdminApi } from '@datenkraft/meetingroom-js-api-client';
import { apiClientRepository } from '../../ApiJsClient/ApiJsClient';

export type AuthContextType = {
  isAuthorized: boolean;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
};

const AuthContext = createContext(undefined as AuthContextType | undefined);

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const adminApi: AdminApi = apiClientRepository.get(AdminApi);

  const login = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    return adminApi
      .validateLogin({
        adminValidateLoginRequest: {
          username: username,
          password: password
        }
      })
      .then(response => {
        if (response.data?.result) {
          authorizeUser(username, password);
        }
        return response.data?.result as boolean;
      })
      .catch(e => {
        console.error(e);
        return false;
      });
  };

  const logout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    setIsAuthorized(false);
  };

  const authorizeUser = (username: string, password: string) => {
    localStorage.setItem('username', username);
    localStorage.setItem('password', btoa(password));
    setIsAuthorized(true);
  };

  useEffect(() => {
    const username: string = localStorage.getItem('username') || '';
    const password: string = atob(localStorage.getItem('password') || '');
    if (!isAuthorized && username && password) {
      login(username, password).then();
    }
  }, []);

  const value: AuthContextType = {
    isAuthorized: isAuthorized,
    login: login,
    logout: logout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
