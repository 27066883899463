import React, { useContext } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { ClientsContext } from '../../../../../app/modules/Clients/context/ClientsContext';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };
  const { clients } = useContext(ClientsContext);

  const clientMenuItems = clients.map(client => (
    <li
      key={client.Id}
      className={`menu-item ${getMenuItemActive(
        '/clients/' + client.Id
      )}`}
      aria-haspopup='true'
    >
      <NavLink className='menu-link' to={'/clients/' + client.Id}>
        <i className='menu-bullet menu-bullet-dot'>
          <span />
        </i>
        <span className='menu-text'>{client.Id}</span>
      </NavLink>
    </li>
  ));

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Components */}
        {/* begin::section */}
        <li className='menu-section '>
          <h4 className='menu-text'>Administration</h4>
          <i className='menu-icon flaticon-more-v2' />
        </li>
        {/* end:: section */}

        {/* Clients */}
        {/* begin::1 Level */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/clients',
            true
          )}`}
          aria-haspopup='true'
          data-menu-toggle='hover'
        >
          <NavLink className='menu-link menu-toggle' to='/clients'>
            <span className='svg-icon menu-icon'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Devices/Tablet.svg')} />
            </span>
            <span className='menu-text'>Clients</span>
            <i className='menu-arrow' />
          </NavLink>
          <div className='menu-submenu '>
            <i className='menu-arrow' />
            <ul className='menu-subnav'>
              <li className='menu-item  menu-item-parent' aria-haspopup='true'>
                <span className='menu-link'>
                  <span className='menu-text'>Clients</span>
                </span>
              </li>
              {/* begin::2 Level */}
              {clientMenuItems}
              {/* end::2 Level */}
            </ul>
          </div>
        </li>
        {/* end::1 Level */}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
