import { Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen } from '../_metronic/layout';
import { BuilderPage } from './pages/BuilderPage';
import { ClientOverviewPage } from './modules/Clients/pages/ClientOverviewPage';
import { ClientPage } from './modules/Clients/pages/ClientPage';
import { ClientProvider } from './modules/Clients/context/ClientContext';

export const BasePage = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /clients. */
          <Redirect exact from='/' to='/clients' />
        }
        {/* Builder page */}
        <Route path='/builder' component={BuilderPage} />
        {/* Clients page */}
        <Route exact path='/clients' component={ClientOverviewPage} />
        {/* Client provider */}
        <ClientProvider>
          {/* Client page */}
          <Route exact path='/clients/:id' component={ClientPage} />
        </ClientProvider>
        {/* Error redirect */}
        <Redirect to='/error' />
      </Switch>
    </Suspense>
  );
};
